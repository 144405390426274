<template>
	<div class="container-register-sleep">
	<SWNavButton
		@click="navigateBack" 
		class="btn-nav-ragister"
		:size="'small'"
		>Back</SWNavButton
	>
	<div class="box-register-sleep">
		<div class="img-sleep-register">
			<img src="../../assets/img/sleepNewLogo.png" alt="" />
		</div>

		<div class="title-sleep-register">
			<p>Password recovery</p>
			<p class="subtitle">{{ this.method === 'email' ? 'Email:' : 'Phone number:' }} {{ this.methodValue }}</p>
		</div>

		<form @submit.prevent="submitHandler">
			<div class="register-input">

				<SWInput
                  class="margin-inp"
                  placeholder="New password"
                  v-model="password"
                  type="password"
                  @input="$v.password.$touch()"
                  :errorMessage="passwordError"
                  :isError="$v.password.$error  && !!passwordError"
                  :hasShowIcon="true"
               ></SWInput>
			
			<SWButton :disabled="disabled" type="submit" variant="primari" size="big"
				>Continue</SWButton
			>
			</div>
		</form>
	</div>
	<Notification :show="notificationShowed" :text="notificationText" :color="notificationColor" @closeNotification="closeNotification" />

 </div>
 </template>
 
 <script>
 import {SWNavButton, SWButton, SWInput } from 'sw-ui-kit';
 import { validationMixin } from 'vuelidate';
 import { required, minLength, maxLength } from 'vuelidate/lib/validators';
 import { hasPassword } from '../../validation/validation';
 import * as Sentry from '@sentry/vue';
 import Notification from '../../components/UI/Notification.vue';

 export default {

 components: {
	SWNavButton,
	SWButton,
	SWInput,
	Notification
 },
 mixins: [validationMixin],
 

 data(){
	return {
		method: '',
		password: '',
		methodValue: '',
		verify_token: '',
		notificationText: '',
			notificationColor: '',
			notificationShowed: false
	}
 },
 validations: {
      password: { required, hasPassword, minLength: minLength(8), maxLength: maxLength(20) },
   },

mounted(){
	this.method = this.$route.params.method;
	this.methodValue = this.$route.params.methodValue || '';
	const email = localStorage.getItem('email');
	if(email && this.method != 'phone'){
		this.methodValue = email;
		this.method = 'email';
	}

	const verify_token = this.$route.query.verify_token;
	this.verify_token = verify_token;

	if(!this.verify_token){
		return this.$router.push({ name: 'forgot' });
	}
      if (verify_token) {
         this.$http
            .post(`auth/forgot/check/${this.method}`, { code: verify_token, phone: this.methodValue })
            .then(response => {
               if (response.status === 202) {
                  //this.$router.push({ name: 'app-forgot-reset', params: { code: verify_token } });
				console.log('token verified')
               }
            })
            .catch(() => {
				this.showNotification('Wrong verification code', 'danger');
				Sentry.captureMessage('Wrong verification code');
				setTimeout(() => {
					this.$router.push({ name: 'forgot'});
				}, 1000);
            });
      }

},

	methods: {
		showNotification(text, color) {
			console.log('show notification')
			this.notificationShowed = true;
			this.notificationText = text;
			this.notificationColor = color;
		},
		closeNotification() {
			this.notificationShowed = false;
			this.$router.push({ name: 'forgot'});
		},
		navigateBack(){
			if (this.method === 'phone'){
				this.$router.push({ name: 'reset-by-pnone' });
			}
			else{
				this.$router.push({ name: 'forgot' });
			}
		},

		async submitHandler() {
			try{
			await this.$http.post(`/auth/forgot/password/${this.method}`, { code: this.verify_token, password: this.password, phone: this.methodValue});
		

			if(this.password){
				this.$router.push({ name: 'recovery-success', params: { success: true } });
			} 
			}catch(e){
				this.showNotification(e.message, 'danger');
				Sentry.captureException(e);
				setTimeout(() => {
					this.$router.push({ name: 'forgot'});
				}, 3000);
			}
      },
	},
	computed:{
		passwordError() {
         if (!this.$v.password.required) return 'The field must be filled';
         if (!this.$v.password.minLength) return 'Password must be at least 8 characters long';
         if (!this.$v.password.maxLength) return 'Password must not exceed 20 characters';
         if (!this.$v.password.hasPassword)
            return 'The password must have Latin capital and small letters,numbers and a special symbol ( ! @ # $ & + - ?)';

         return '';
      },
  disabled() {
         return this.$v.password.$invalid;
      },
	}
 };
 </script>

<style lang="sass" scoped>
.name-registar
	display: flex
	min-width: 175px
	width: fit-content
	margin-bottom: 15px
.corent-margin
	margin-right: 8px
.reg-box
	display: flex
	justify-content: center
.reg-box:nth-child(1)
	margin-right: 20px

.reg-box
	@media screen and (max-width:380px)
		margin-top: -10px
.btn-nav-ragister
	text-align: left
.box-register-sleep
	max-width: 356px
	margin: 0 auto
.container-register-sleep
	width: 100%
	height: 100%
	margin: 0 auto
	max-width: 1246px
	padding: 15px 20px
	display: flex
	flex-direction: column
	@media screen and (max-width:380px)
		padding: 15px 20px
.img-sleep-register
	max-width: 128px
	margin: 0 auto
	padding-top: 30px
	padding-bottom: 24px
	@media screen and (max-width:380px)
		padding-bottom: 10px
		padding-top: 10px

.title-sleep-register
	font-size: 32px
	font-weight: 700
	line-height: 41.6px
	text-align: center
.title-sleep-support
	text-align: left
.subtitle
	font-family: 'Roboto', sans-serif
	font-size: 14px
	font-weight: 500
	line-height: 18.2px
	color: #11151F
	margin-top: 16px
	margin-bottom: 16px
.resend
	font-weight: 400
	font-size: 12px
	line-height: 15.6px
	color: #11151F

.paragraf-register
	font-size: 14px
	font-weight: 500
	line-height: 18.2px
	text-align: center
	margin-top: 16px
	margin-bottom: 16px
	@media screen and (max-width:380px)
		margin-bottom: 5px
.paragraf-current
	font-weight: 500
	line-height: 18.2px
	text-align: center
	color: #828282
	cursor: pointer
	padding: 15px
.active
	color: #11151F
	text-decoration: underline
.paragraf-current-one
	font-weight: 500
	line-height: 18.2px
	text-align: center
	padding-top: 15px
.footer-sleep-register
	color: #828282
	font-size: 12px
	font-weight: 400
	line-height: 16px

.button-social
	display: flex
	justify-content: space-between
.btn-reg
	width: 100%
	font-size: 14px
	font-weight: 400
.btn-reg:nth-child(2)
	margin-right: 10px
	margin-left: 10px
.register-input
	margin-bottom: 32px
	@media screen and (max-width:380px)
		margin-bottom: 10px
.margin-inp
	margin-bottom: 16px
.btn-nav-ragister
	margin-bottom: 20px
	font-weight: 400
	margin-top: 64px

@media screen and (max-width:1024px)
	.btn-nav-ragister
		margin-top: 16px
</style>